<template>
  <div class="check-item" ref="checkItem">
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, watch, ref } from 'vue';
const store = useStore();
const checkItem = ref(null);
const checkData = computed(() => store.getters.getCheckData);
watch(checkData, (newCheck, oldCheck) => {
  checkItem.value.innerHTML = newCheck;
});
</script>
<style lang="scss">
.check {
  &-item {
    width: 576px;
    display: flex;
    justify-content: center;
    background-color: rgba(230, 255, 234, 0.184) !important;
  }
}
</style>
