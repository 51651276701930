import axios from 'axios';
const checkApi = axios.create({
  // baseURL: 'http://localhost/e-check-php', // локалка
  baseURL: 'https://api.e-check.skyservice.online',
});


const getCurrentCheck = (checkId) => {
  return checkApi.post('?section=checks&action=getCheck', { checkId }).then((response) => response.data);
};

export { getCurrentCheck };
