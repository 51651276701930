<template>
  <button @click="printCheck" class="btn outline">Роздрукувати</button>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
let checkId = ref('');
const saveDocTitle = document.title;
let newTitle = ref('');
onMounted(() => {
  // Отримання checkId з маршруту та присвоєння його змінній checkId
  checkId.value = getCheckIdParam();
});
const printCheck = () => {
  document.title = checkId.value ? `Чек: ${checkId.value}` : 'Чек';
  window.print();
  document.title = saveDocTitle;
};

// Функція для отримання параметра checkId з маршруту
const getCheckIdParam = () => {
  return route.params.checkId;
};
</script>
<style lang="scss"></style>
