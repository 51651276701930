<template>
  <div class="check-wrapper" v-if="checkId.length === 36" v-show="checkData">
    <div class="check-data">
      <Check />
      <div class="check-func">
        <div class="check-func__main">
          <PrintPdf />
          <PrintCheck />
        </div>
        <GoToDPS v-if="rroData" />
      </div>
    </div>
  </div>
</template>

<script setup>
import Check from '@/components/Check.vue';

import { onMounted, ref, computed } from 'vue';
import { getCurrentCheck } from '@/Api';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import PrintPdf from '@/components/PrintPdf.vue';
import GoToDPS from '@/components/GoToDPS.vue';
import PrintCheck from '@/components/PrintCheck.vue';

const store = useStore(); // Використання Vuex store
const route = useRoute(); // Отримання поточного маршруту
const router = useRouter(); // Отримання роутера

let checkId = ref('');


onMounted(() => {
  try {
    // Отримання checkId з маршруту та присвоєння його змінній checkId
    checkId.value = getCheckIdParam();
    // Перевірка, чи ідентифікатор чеку має правильну довжину
    if (checkId.value.length === 36) {
      // Отримання даних за допомогою API
      getCurrentCheck(checkId.value).then((response) => {
        if (response.status === 'done') {
          if (response.data.html) {
            // Оновлення даних чеку в Vuex store
            store.dispatch('updateCheckData', response.data.html);
            // Якщо є додаткові дані про чек, оновлення їх в Vuex store
            if (response.data.rroData) {
              store.dispatch('updateRroData', response.data.rroData);
            }
          } else {
            router.push({ name: 'error', query: { check: 'error' }});
          }
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
});

// Функція для отримання параметра checkId з маршруту
const getCheckIdParam = () => {
  return route.params.checkId;
};

const rroData = computed(() => store.getters.getRroData);
const checkData = computed(() => store.getters.getCheckData);
</script>

<style lang="scss">
.checkfade-enter-active,
.checkfade-leave-active {
  transition: opacity 0.5s ease;
}

.checkfade-enter-from,
.checkfade-leave-to {
  opacity: 0;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.check {
  &-wrapper {
    display: flex;
    padding: 50px 0;
    justify-content: center;
  }
  &-data {
    display: flex;
    justify-content: center;
  }
  &-func {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    &__main {
      display: flex;
      flex-direction: column;
    }
  }
}
@media print {
  .check {
    &-func {
      display: none;
    }
  }
  #downimg {
    box-shadow: none !important;
    background-color: transparent !important;
  }
}

@media screen and (max-width: 900px) {
  .check {
    &-data {
      flex-direction: column-reverse;
    }
    &-func {
      margin-bottom: 20px;
      margin-left: 0;
      &__main {
        flex-direction: row;
        justify-content: center;
        gap: 10px;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .btn {
    min-width: 150px !important;
    font-size: 14px !important;
  }
  .check {
    &-data {
      padding: 20px;
      width: 100%;
    }
    &-item {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  #downimg {
    width: 100% !important;
  }
}

@media screen and (max-width: 500px) {
  .btn {
    min-width: 150px !important;
  }
}
@media screen and (max-width: 350px) {
  .btn {
    min-width: 130px !important;
    font-size: 11px !important;
  }
}
</style>
