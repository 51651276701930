<template>
  <div class="error">
    <h1 class="error-code" v-if="routeId === 'error'">500</h1>
    <h1 class="error-code" v-else>404</h1>
    <h2 class="error-message" v-if="routeId !== 'error'">Error! Check {{ routeId }} not found!</h2>
    <h2 class="error-message" v-else>Error! There is an error on the server!</h2>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
const route = useRoute();

const routeId = computed(() => {
  if(route.query.check === 'error'){
    return 'error';
  }else{
    return route.query.check ? `'${route.query.check}'` : ``;
  }
});
</script>
<style lang="scss">
.error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  &-code {
    margin-bottom: 20px;
    font-size: 120px;
  }
  &-message {
    font-size: 18px;
    color: rgb(143, 143, 143);
  }
}
</style>
