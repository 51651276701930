import { createStore } from 'vuex';

export default createStore({
  state: {
    checkData: null, // Ініціалізація поля checkData
    rroData: null, // Ініціалізація поля rroData
  },
  getters: {
    getCheckData(state) {
      return state.checkData;
    }, // Геттер для отримання значення checkData
    getRroData(state) {
      return state.rroData;
    }, // Геттер для отримання значення rroData
  },
  mutations: {
    setCheckData(state, newData) {
      state.checkData = newData; // Мутація для встановлення нового значення checkData
    },
    setRroData(state, newData) {
      state.rroData = newData; // Мутація для встановлення нового значення rroData
    },
  },
  actions: {
    updateCheckData({ commit }, newData) {
      commit('setCheckData', newData); // Дія для оновлення значення checkData
    },
    updateRroData({ commit }, newData) {
      commit('setRroData', newData); // Дія для оновлення значення rroData
    },
  },
  modules: {},
});
