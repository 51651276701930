<template>
  <div class="link go-to-dps" @click="goToDPS">Переглянути у ДПС</div>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';
const store = useStore();
const rroData = computed(() => store.getters.getRroData);
const goToDPS = () => {
  const { taxNumber, rroSerialNum, date, time, summ } = rroData.value;
  const baseUrl = 'https://cabinet.tax.gov.ua/cashregs/check';
  let url = `${baseUrl}?id=${taxNumber}&type=3&fn=${rroSerialNum}&date=${date}&time=${time}&sm=${summ}`;
  window.open(url, "_blank");
};
</script>
<style lang="scss">
.go-to-dps {
  color: rgb(38, 52, 40);
}
</style>
