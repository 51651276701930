import { createRouter, createWebHistory } from 'vue-router';
import CheckView from '@/views/CheckView.vue'; // Імпорт компонента CheckView
import ErrorView from '@/views/ErrorView.vue'; // Імпорт компонента ErrorView

const routes = [
  {
    path: '/:checkId',
    name: 'checks',
    component: CheckView,
  },
  {
    path: '/check-error', // Шлях до сторінки помилки
    name: 'error',
    component: ErrorView,
  },
];

const router = createRouter({
  history: createWebHistory(), // Історія для роутера (використання HTML5 History API)
  routes,
  strict: true,
});

// Логіка перед переходом на кожний маршрут
router.beforeEach((to, from) => {
  if (to.params.checkId && to.params.checkId.length !== 36) { // Перевірка, чи передано параметр checkId та його довжину
    return `/check-error?check=${to.params.checkId}`; // Повернення шляху до сторінки помилки з параметром check
  } else if (!to.params.checkId && to.fullPath === '/') { // Перевірка, чи відбувається перехід на головну сторінку
    return `/check-error`; // Перехід до сторінки помилки
  }
});

export default router;
