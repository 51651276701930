<template>
  <button @click="printCheckPdf" class="btn outline">Зберегти</button>
</template>
<script setup>
import html2pdf from 'html2pdf.js'; // Імпорт бібліотеки для конвертації HTML в PDF

const printCheckPdf = () => {
  // Отримання елементу з класом 'check-item', який потрібно роздрукувати
  const element = document.getElementsByClassName('check-item')[0];
  // Опції для налаштування PDF
  const opt = {
    margin: [0.98, 1.1],
    filename: 'check.pdf', // Ім'я файлу PDF
    image: { type: 'jpeg', quality: 1 }, // Тип та якість зображення (для вставки зображень)
    html2canvas: { scale: 2 }, // Масштабування зображення перед конвертацією
    jsPDF: { orientation: 'portrait', unit: 'in' }, // Орієнтація сторінки (альбомна або портретна)
    pagebreak: { mode: 'avoid-all', avoid: 'img' },
  };

  // Виклик функції для конвертації HTML у PDF, налаштування опцій та збереження документа
  html2pdf().from(element).set(opt).save();
};
</script>
<style lang="scss"></style>
